import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { EntData } from '@bazis/shared/models/srv.types';

@Component({
    selector: 'bazis-multiple-selection-option',
    templateUrl: './multiple-selection-option.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisMultipleSelectionOptionComponent {
    @Input() option: EntData;

    @Input() multiple: boolean = false;

    @Input() byCheckbox: boolean = false;

    @Input() values: (string | number | boolean)[];

    @Output() toggled = new EventEmitter();

    toggleValue(e) {
        this.toggled.emit(e);
    }
}
