import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'bazis-item-with-icon-value',
    templateUrl: './item-with-icon-value.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisItemWithIconValueComponent {
    // @Input() infiniteListTpl: TemplateRef<any>;

    // @Input() selectedValueTpl: TemplateRef<any>;

    @Input() item;

    @Input() iconSize: 'xxs' | 'xs' | 's' | 'md' | 'l' | 'xl' = 'l';
}
