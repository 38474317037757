<ng-container *ngIf="tooltipSettings?.targetSide === 'right' && tooltipText">
    <bazis-interrogation
        iconSize="xs"
        [tooltipText]="tooltipText"
        [tooltipOffset]="[8, 4]"
        [tooltipEventType]="tooltipSettings?.eventType || 'hover'"
        [tooltipPlacement]="tooltipSettings?.placement || 'bottom-end'"
    ></bazis-interrogation>
</ng-container>
<button (click)="toggleList()">
    <bazis-icon
        [icon]="showList ? iconUp : iconDown"
        size="s"
    ></bazis-icon>
</button>
