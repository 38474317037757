import {
    Component,
    ChangeDetectionStrategy,
    Input,
    HostBinding,
    EventEmitter,
    Output,
} from '@angular/core';
import { TooltipSettings } from '@bazis/form/models/form-element.types';

@Component({
    selector: 'bazis-control-select-end',
    templateUrl: './control-select-end.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisControlSelectEndComponent {
    @HostBinding('class.bazis-control__field-group__end') get hostClass() {
        return true;
    }

    @Input() tooltipText: string = null;

    // настройки отображения и работы тултипа
    @Input() tooltipSettings: TooltipSettings = null;

    @Input() showList: boolean = false;

    @Input() iconUp: string = 'caret-up';

    @Input() iconDown: string = 'caret-down';

    @Output() toggled = new EventEmitter();

    constructor() {}

    toggleList() {
        this.toggled.emit();
    }
}
