import { Injectable } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { BazisConfigurationService } from '@bazis/configuration.service';
import * as bazisConfig from '@bazis/configuration.service';
import { MaskSettings } from '@bazis/form/models/form-element.types';

export const AVAILABLE_LANGUAGES = ['ru'];
export const SELECTED_LANGUAGE =
    localStorage.getItem('lang') &&
    AVAILABLE_LANGUAGES.indexOf(localStorage.getItem('lang') as string) > -1
        ? localStorage.getItem('lang') || AVAILABLE_LANGUAGES[0]
        : AVAILABLE_LANGUAGES[0];

export const API_DATETIME_FORMAT_Z = 'YYYY-MM-DD[T]HH:mm:ss[Z]';

export const ROLE = {
    client: 'MP_LK_CLIENT',
    systemOperator: 'MP_LK_OPERATOR',
    manager: 'MP_LK_MANAGER',
    partner: 'MP_LK_PARTNER',
    agent: 'MP_LK_AGENT',
    anonymous: 'MP_LK_PUBLIC_USER',
    empty: null,
};

export const MOBILE_ROLES = [];

export const ROLE_ACCOUNT_TYPE = {};

export const DISABLE_NOTIFICATIONS_ROLES = [ROLE.anonymous];

export const SHARE_REPLAY_SETTINGS = { bufferSize: 1, refCount: true };

export const USER_STATUSES = {
    active: {
        color: 'success',
        nameKey: 'users.status.active',
    },
    waiting: {
        color: 'secondary',
        nameKey: 'users.status.waiting',
    },
    blocked: {
        color: 'danger',
        nameKey: 'users.status.blocked',
    },
};

export const HEADER_MENU: {
    [index: string]: {
        titleKey: string;
        url?: string;
        children?: { titleKey: string; url: string }[];
        hasRefInfo?: boolean;
    }[];
} = {
    [ROLE.client]: [
        {
            titleKey: 'header.services',
            url: '/services',
        },
        {
            titleKey: 'header.createBid',
            url: '/draft/bid-payment/new/',
        },
        {
            titleKey: 'header.myBids',
            url: '/payments/bids',
        },
        // {
        //     titleKey: 'header.referral',
        //     hasRefInfo: false,
        //     url: '/referral-program',
        // },
        // {
        //     titleKey: 'header.referral',
        //     hasRefInfo: true,
        //     url: '/referral-info',
        // },
    ],
    [ROLE.agent]: [
        {
            titleKey: 'header.bids',
            url: '/payments/lots',
        },
        {
            titleKey: 'header.myBids',
            url: '/payments/bids',
        },
        // {
        //     titleKey: 'header.referral',
        //     hasRefInfo: false,
        //     url: '/referral-program',
        // },
        // {
        //     titleKey: 'header.referral',
        //     hasRefInfo: true,
        //     url: '/referral-info',
        // },
    ],
    [ROLE.systemOperator]: [
        {
            titleKey: 'header.masterAccounts',
            url: '/master-accounts',
        },

        {
            titleKey: 'header.bids',
            children: [
                {
                    titleKey: 'header.partnerBids',
                    url: '/payments/bids/partner',
                },
                {
                    titleKey: 'header.publicBids',
                    url: '/payments/bids/public',
                },
            ],
        },

        {
            titleKey: 'header.allUsers',
            children: [
                {
                    titleKey: 'header.partnerUsers',
                    url: '/users/partner',
                },
                {
                    titleKey: 'header.publicUsers',
                    url: '/users/public',
                },
            ],
        },
        {
            titleKey: 'header.documents',
            url: '/payment-documents',
        },
        {
            titleKey: 'header.analytics',
            url: '/analytics',
        },
        {
            titleKey: 'header.fee',
            url: '/fee',
        },
        {
            titleKey: 'header.invoices',
            url: '/invoices',
        },
        {
            titleKey: 'header.referralPartners',
            url: '/referrals',
        },
    ],
    [ROLE.partner]: [
        {
            titleKey: 'header.bids',
            url: '/payments/bids/partner',
        },
        {
            titleKey: 'header.allUsers',
            url: '/users',
        },
        {
            titleKey: 'header.partnerLink',
            url: '/partner/summary',
        },
        {
            titleKey: 'header.documents',
            url: '/payment-documents',
        },
        {
            titleKey: 'header.analytics',
            url: '/analytics',
        },
        {
            titleKey: 'header.fee',
            url: '/fee',
        },
        // {
        //     titleKey: 'header.referral',
        //     hasRefInfo: false,
        //     url: '/referral-program',
        // },
        // {
        //     titleKey: 'header.referral',
        //     hasRefInfo: true,
        //     url: '/referral-info',
        // },
    ],
    [ROLE.manager]: [
        {
            titleKey: 'header.bids',
            url: '/payments/bids/partner',
        },
        {
            titleKey: 'header.allUsers',
            url: '/users',
        },
        {
            titleKey: 'header.partnerLink',
            url: '/partner/summary',
        },
        {
            titleKey: 'header.documents',
            url: '/payment-documents',
        },
        {
            titleKey: 'header.analytics',
            url: '/analytics',
        },
        {
            titleKey: 'header.fee',
            url: '/fee',
        },
        // {
        //     titleKey: 'header.referral',
        //     hasRefInfo: false,
        //     url: '/referral-program',
        // },
        // {
        //     titleKey: 'header.referral',
        //     hasRefInfo: true,
        //     url: '/referral-info',
        // },
    ],
    [ROLE.anonymous]: [
        {
            titleKey: 'header.services',
            url: '/services',
        },
        // {
        //     titleKey: 'header.referral',
        //     url: '/referral-program',
        // },
    ],
};

export const FOOTER_MENU = {};

export const USER_MENU = [
    {
        titleKey: 'header.personalInfo',
        url: '/profile/personal-info',
        icon: 'portrait',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.notifications',
        url: '/profile/notifications',
        icon: 'bell',
        excludeForRoles: [],
    },
    {
        type: 'roles',
        titleKey: 'header.enterAs',
        icon: 'arrow-right',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.logout',
        url: '/logout',
        icon: 'power',
        excludeForRoles: [],
    },
];

export const DEFAULT_LINK_ACTIVE_MATCH_OPTIONS: IsActiveMatchOptions =
    bazisConfig.DEFAULT_LINK_ACTIVE_MATCH_OPTIONS;

export const TG_BOT = '@coinsgo_notify_bot';
export const TG_COMMAND = '/start';

export const DOCUMENT_LINKS = {
    privacyPolicy: '/legal-documents/privacy_policy',
    userAgreement: '/legal-documents/site_rules',
    cookiePolicy: '/legal-documents/cookie_policy',
};

export const UNDERCONSTRUCTION_TITLE = {
    feature: 'title.featureUnderconstruction',
    section: 'title.sectionUnderconstruction',
};

export const SUCCESS_LOGIN_PAGE = '/success-login';
export const SUCCESS_INVITE_PAGE = '/success-invite';

export const PHONE_MASK = bazisConfig.PHONE_MASK;
export const PHONE_PATTERN = bazisConfig.PHONE_PATTERN;
export const PHONE_MASK_SETTINGS: Partial<MaskSettings> = bazisConfig.PHONE_MASK_SETTINGS;
export const PHONE_INPUT_TRANSFORM_FN = bazisConfig.PHONE_INPUT_TRANSFORM_FN;

export const TG_MASK_SETTINGS: Partial<MaskSettings> = {
    mask: 'KKKKKTTTTTTTTTTTTTTTTTTTTTTTTTTT',
    prefix: '@',
    placeHolderCharacter: '',
    dropSpecialCharacters: [],
    validation: false,
    keepCharacterPositions: false,
    showMaskTyped: true,
    patterns: {
        T: {
            pattern: new RegExp('^[a-zA-Z0-9_]'),
            optional: true,
        },
        K: {
            pattern: new RegExp('^[a-zA-Z0-9_]'),
            optional: false,
        },
    },
    outputTransformFn: (value) => {
        return value ? `@${value}` : '';
    },
};

export const TG_INPUT_TRANSFORM_FN = (value) =>
    value && value.indexOf('@') > -1 ? value.split('@').join('') : value;

export const BIC_PATTERN = bazisConfig.BIC_PATTERN;
export const RRC_PATTERN = bazisConfig.RRC_PATTERN;
export const BANK_ACCOUNT_PATTERN = bazisConfig.BANK_ACCOUNT_PATTERN;
export const CORRESPONDENT_ACCOUNT_PATTERN = bazisConfig.CORRESPONDENT_ACCOUNT_PATTERN;
export const OKTMO_MASK_SETTINGS = bazisConfig.OKTMO_MASK_SETTINGS;

export const TG_PATTERN = /^@[a-zA-Z0-9_]{5,32}$/;

export const INDIVIDUAL_INN_PATTERN = /^([0-9]{12})$/;
export const PASSPORT_CODE_PATTERN = /^([0-9]{3}-[0-9]{3})$/;
export const PASSPORT_PATTERN = /^([0-9]{2}\s[0-9]{2}\s[0-9]{6})$/;
export const PASSPORT_MASK_SETTINGS: Partial<MaskSettings> = {
    pipeMask: '00 00 000000',
    mask: '00 00 000000',
    placeHolderCharacter: '_',
    dropSpecialCharacters: [],
    validation: false,
    keepCharacterPositions: false,
    showMaskTyped: true,
};
export const PASSPORT_CODE_MASK_SETTINGS: Partial<MaskSettings> = {
    pipeMask: '000-000',
    mask: '000-000',
    placeHolderCharacter: '_',
    dropSpecialCharacters: [],
    validation: false,
    keepCharacterPositions: false,
    showMaskTyped: true,
};

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService extends BazisConfigurationService {
    protected configuration: any = {
        tiles: {
            default: {
                url: 'https://{s}.map.asu.big3.ru/street/{z}/{x}/{y}.png',
                size: 256,
            },
            grey: {
                url: 'https://api.mapbox.com/styles/v1/smart-waste/cl7t82ptn006315lg5yiys80b/tiles/256/{z}/{x}/{y}?&access_token=pk.eyJ1Ijoic21hcnQtd2FzdGUiLCJhIjoiY2w3dDdyYWtvMDFzcjNucWxkaDR6aXk3dSJ9.DwsYQbL40ED-guYA9sE-0g',
                size: 256,
            },
        },
        emptyInputValue: '-',
        defaultLocation: [55.75222, 37.61556],
        vehicleNumberMask: [],
        requisites: {
            inn: 5029287549,
            kpp: 502901001,
            ogrn: 1245000088200,
        },
    };

    protected settingsUrl = 'money_platform/settings';

    availableLanguages = AVAILABLE_LANGUAGES;

    selectedLanguage = SELECTED_LANGUAGE;

    get isNominalAccountAvailable() {
        return this.configuration?.USE_NOMINAL_ACC;
        //return false;
    }

    get requisites(): any {
        return this.configuration?.requisites || null;
    }

    get allSettings() {
        return this.configuration;
    }
}
