import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { EntData, SimpleData } from '@bazis/shared/models/srv.types';

@Component({
    selector: 'bazis-select-native',
    templateUrl: './select-native.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BazisSelectNativeComponent {
    @Input() attrId: string;

    @Input() options: EntData[] | SimpleData[] = null;

    @Input() placeholder: string;

    @Input() hasOther: boolean = false;

    @Input() textOther: string;
}
