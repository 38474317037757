import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { BazisToastService } from '@bazis/shared/services/toast.service';
import { ROLE } from '@app/configuration.service';
import { MpAuthService } from '@shared/services/auth.service';
import { BazisSrvService } from '@bazis/shared/services/srv.service';

@Injectable({
    providedIn: 'root',
})
export class PartnerGuard {
    constructor(private srv: BazisSrvService, private router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> {
        const queryParams = { ...route.queryParams };
        const partnerLabel = queryParams.partner_label;
        const refLabel = queryParams.ref;
        if (!partnerLabel && !refLabel) return of(true);

        delete queryParams.partner_label;
        delete queryParams.ref;

        return this.srv
            .commonGetRequest$('partnership/join', { partner_label: partnerLabel, ref: refLabel })
            .pipe(
                map((r) => {
                    this.router.navigate(['/login'], {
                        queryParams: { registration: true },
                    });
                    return true;
                }),
                catchError((e) => {
                    this.router.navigate(['/login'], {
                        queryParams: { registration: true },
                    });
                    return of(null);
                }),
            );
    }
}
