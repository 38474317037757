<ng-container *transloco="let t">
    <!-- Нативный селект, для поддержки всяческих девайсов и ограниченных возможностей -->
    <!-- TODO: подумать, как лучше оформить, так как иконка может быть не всегда
                        запас отступа под иконку в кастоме -->
    <select
        [attr.aria-labelledby]="attrId"
        [attr.placeholder]="placeholder"
    >
        <option *ngFor="let option of options">
            <!-- TODO: icon or text version of icon?? -->
            <ng-container *ngIf="option.$snapshot.name">
                {{ option.$snapshot.name }}
            </ng-container>
            <ng-container *ngIf="option.$snapshot.nameKey">
                {{ t(option.$snapshot.nameKey, option.$snapshot.nameParams) }}
            </ng-container>
        </option>
        <option *ngIf="hasOther" value="'other'">
            {{ textOther }}
        </option>
    </select>
</ng-container>
